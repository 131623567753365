import MockAdapter from "axios-mock-adapter";

import factory from "@utils/factory";
import order from "./order.model";
import search from "./search.model";

import { get } from "@lib/lodash-replace";

// Model factory
factory.define("order", order);
factory.define("search", search);

const mocked = (client, routes) => {
    const mock = new MockAdapter(client, {
        onNoMatch: "passthrough",
    });

    // Mock the Get USER ROUTE if set
    if (routes.GET_ORDERS.mock) {
        mock.onPost(routes.GET_ORDERS.path).reply((config) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        200,
                        {
                            result: factory.create(
                                "order",
                                {},
                                get(config, "params.count", 10)
                            ),
                        },
                    ]);
                }, routes.GET_ORDERS.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_ORDERS.path).passThrough();
    }

    // Mock the Get USER ROUTE if set
    if (routes.GET_SEARCHES.mock) {
        mock.onGet(routes.GET_SEARCHES.path).reply((config) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        200,
                        {
                            result: factory.create(
                                "search",
                                {},
                                get(config, "params.count", 10)
                            ),
                        },
                    ]);
                }, routes.GET_SEARCHES.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_SEARCHES.path).passThrough();
    }
};
export default mocked;
