import httpClient from "@api/client";
import { config } from "@config";

/**
 * setup: call before each api to setup mocking if enabled. Mocking is enabled via env vars
 */
const setup = () => {
    if ("Sidebar" in window.mocks) {
        window.mocks.Sidebar(httpClient);
    }
};

export default {
    async getNavigation(role) {
        let items = [];
        if (config("sidebar.navigation") === "static") {
            items = config("sidebar.items");
        } else {
            setup();
            let res = await httpClient.get("/navigation");
            items = res.data;
        }
        return items.filter((item) => {
            return item.roles.includes(role);
        });
    },
};
