import httpClient from "@api/client";

export default {
    async getReference(url) {
        let res = await httpClient.get(url);

        return res.data.data;
    },
    async saveReference(form) {
        const method = "post";
        const url = form.url;
        const data = form.data

        let res = await httpClient({
            method,
            url,
            data,
        });
        return res.data.data;
    },
};