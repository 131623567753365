/**
 * Module
 */
import api from "../api";

const initialState = () => ({
    items: [],
});
const state = initialState();

const getters = {
    items: (state) => state.items,
};

const actions = {
    getNavigation: async ({ commit }, role) => {
        commit("SET_LOADING", true, { root: true });
        commit("SET_ITEMS", await api.getNavigation(role));
        commit("SET_LOADING", false, { root: true });
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_ITEMS: (state, payload) => {
        state.items = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
