<template>
    <v-card>
        <v-toolbar dense color="primary" dark class="mb-4">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <h4>{{prompt}}</h4>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="alternate1 lighten-3" class="mr-2" @click.stop="$emit('close')">
                <v-icon class="mr-2">mdi-close</v-icon>Cancel
            </v-btn>
            <v-btn color="secondary" @click.stop="$emit('confirm')">
                <v-icon class="mr-2">mdi-check</v-icon>OK
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "psi-confirm-dialog",
    props: {
        title: {
            required: true,
            type: String,
        },
        prompt: {
            required: true,
            type: String,
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped>
</style>