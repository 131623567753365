// import the api endpoints
import api from "../api";

const initialState = () => ({
    user: {},
    users: [],
    loggedIn: false,
    verified: false,
});

const state = initialState();

const getters = {
    user: (state) => state.user,
    users: (state) => state.users,
    loggedIn: (state) => state.loggedIn,
    verified: (state) => state.verified,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async logout({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            await api.logout();
            commit("RESET", true, { root: true });
            // Getting the user after a logout should force a redirect
            await api.getUser();
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getUser({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            const user = await api.getUser();
            commit("SET_USER", user);
            commit("SET_LOADING", false, { root: true });
            commit("SET_INITIALIZED", true, { root: true });
            return user;
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
    async getUsers({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            const users = await api.getUsers();
            commit("SET_USERS", users);
            commit("SET_LOADING", false, { root: true });
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_USER: (state, payload) => {
        state.user = payload;
        state.loggedIn = Object.keys(state.user).length > 0;
        state.verified = payload.is_email_verified;
    },
    SET_USERS: (state, payload) => {
        state.users = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
