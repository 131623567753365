<template>
    <v-snackbar
        v-model="show"
        :color="color"
        top
        right
        :timeout="$config('settings.messageTimeout')"
    >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn icon dark text v-bind="attrs" @click="show = false">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            message: "",
            color: "",
        };
    },

    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === "Snackbar/SET_MESSAGE") {
                this.message = state.Snackbar.content;
                this.color = state.Snackbar.color;
                this.show = true;
            }
        });
    },
};
</script>