import httpClient from "@api/client";
import routes from "./orders.routes.json";
import { transform } from "./transform";

const setup = () => {
    if ("Orders" in window.mocks) {
        window.mocks.Orders(httpClient, routes);
    }
};

export default {
    async getOrders(email, count = 10) {
        setup();
        let res = await httpClient.post(routes.GET_ORDERS.path, {
            email,
            count,
        });
        return transform(res.data.result);
    },
    async getSearches(count = 10) {
        setup();
        let res = await httpClient.get(routes.GET_SEARCHES.path, {
            params: {
                count,
            },
        });
        return res.data.result;
    },
};
