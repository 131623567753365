let definitions = {};

export default {
    create(modelName, args = {}, count = 1) {
        let models = [];

        if (!Object.keys(definitions).includes(modelName)) {
            console.log(
                "Error in factory creation, can't locate definition for " +
                    modelName
            );
        } else {
            for (let i = 0; i < count; i++) {
                let model = definitions[modelName].faker();
                Object.keys(model).forEach((key) => {
                    if (Object.keys(args).includes(key)) {
                        model[key] = args[key];
                    }
                });
                models.push(model);
            }
        }
        return models;
    },
    define(modelName, faker) {
        definitions[modelName] = { faker };
    },
};
