<template>
    <psi-form-element :label="label" :required="required">
        <v-text-field
            v-if="mask.length > 0"
            v-model="model"
            v-mask="mask"
            v-bind="baseStyle"
            :class="[{'mt-2':$vuetify.breakpoint.smAndUp},{'mt-1':$vuetify.breakpoint.smAndDown}]"
            :prepend-inner-icon="icon"
            :placeholder="placeholder"
            :hint="hint"
            :persistent-hint="persistentHint"
            :rules="rules"
            :label="label"
            :disabled="disabled"
            :dusk="name"
            :required="required"
            @input="$emit('input', model)"
        ></v-text-field>
        <v-text-field
            v-else
            v-model="model"
            v-bind="baseStyle"
            :class="[{'mt-2':$vuetify.breakpoint.smAndUp},{'mt-1':$vuetify.breakpoint.smAndDown}]"
            :prepend-inner-icon="icon"
            :placeholder="placeholder"
            :hint="hint"
            :persistent-hint="persistentHint"
            :rules="rules"
            :label="label"
            :disabled="disabled"
            :dusk="name"
            :required="required"
            @input="$emit('input', model)"
        ></v-text-field>
    </psi-form-element>
</template>
<script>
import { mask } from "vue-the-mask";

export default {
    name: "psi-form-text-field",
    directives: {
        mask,
    },
    components: {},
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        mask: {
            type: [String],
            required: false,
            default: "",
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
        persistentHint: {
            type: Boolean,
            required: false,
            default: false,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            model: this.value,
            error: false,
            baseStyle: {
                height: "40",
                solo: true,
                color: "primary",
            },
        };
    },
    watch: {
        value() {
            this.model = this.value;
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>