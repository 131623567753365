import httpClient from "@api/client";
import routes from "./templates.routes.json";
import { transform } from "./transform";

const EXCLUDE_FILTER_PARAMS = [];

const setup = () => {
    if ("Templates" in window.mocks) {
        window.mocks.Templates(httpClient, routes);
    }
};

const getTemplateParams = (filter) => {
    let params = {};
    if (Object.keys(filter).length > 0) {
        params["searchFields"] = Object.keys(filter)
            .map((key) => {
                return EXCLUDE_FILTER_PARAMS.includes(key) ||
                    filter[key].length == 0
                    ? ""
                    : `${key}:=`;
            })
            .filter(String)
            .join(";");

        params["search"] = Object.keys(filter)
            .map((key) => {
                return EXCLUDE_FILTER_PARAMS.includes(key) ||
                    filter[key].length == 0
                    ? ""
                    : `${key}:${filter[key]}`;
            })
            .filter(String)
            .join(";");
        params["searchJoin"] = "and";
    }
    return params;
};

export default {
    async getTemplates(count = 10, asArray = false, filter) {
        setup();
        let res = await httpClient.get(routes.GET_TEMPLATES.path, {
            params: { 
                count,
                ...getTemplateParams(filter)
             },
        });

        return asArray ? res.data.data : transform(res.data.data);
    },
    async saveTemplate(template) {
        setup();
        // The store or create route is a post, the update is a put
        const method = template.id === 0 ? "post" : "put";
        const url =
            template.id == 0
                ? routes.SAVE_TEMPLATE.path
                : `${routes.SAVE_TEMPLATE.path}/${template.id}`;

        let res = await httpClient({
            method,
            url,
            data: template,
        });
        return res.data.data;
    },
    async deleteTemplate(template) {
        setup();
        let res = await httpClient.delete(
            `${routes.DELETE_TEMPLATE.path}/${template.id}`
        );
        return res.data;
    },
};
