<template>
    <psi-form-element :label="label" :required="required">
        <v-currency-field
            ref="currency"
            v-model="currency"
            :placeholder="placeholder"
            :disabled="disabled"
            :rules="rules"
            height="40"
            solo
            color="primary"
            :class="[{'mt-2':$vuetify.breakpoint.smAndUp},{'mt-1':$vuetify.breakpoint.smAndDown}]"
            v-bind="currency_config"
            :dusk="'currency-input'"
            :label="label"
            @input="$emit('input', currency)"
        ></v-currency-field>
    </psi-form-element>
</template>
<script>
export default {
    name: "psi-form-currency-text-field",
    components: {},
    props: {
        value: {
            required: false,
            validator: (prop) =>
                typeof prop === "string" ||
                typeof prop === "number" ||
                prop === null,
        },
        label: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            currency: this.value ? this.value : "",
            currency_config: {
                decimal: ".",
                thousands: ",",
                prefix: "$",
                suffix: "",
                precision: 2,
                masked: false,
                allowBlank: false,
                min: Number.MIN_SAFE_INTEGER,
                max: Number.MAX_SAFE_INTEGER,
            },
        };
    },
    watch: {
        value() {
            this.currency = this.value;
        },
    },
};
</script>

<style scoped>
</style>