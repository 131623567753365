export const transform = (data) => {
    return data.map((item) => {
        // add a missing contact
        if (!("contact" in item) || item.contact == null) {
            item.contact = {
                name: "Unknown Contact",
                phone_formatted: item.phone_formatted,
                type: "N/A",
            };
        }
        return item;
    });
};
