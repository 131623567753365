<template>
    <v-card>
        <v-card-title class="py-1" :class="headingColor">
            <div class="d-flex align-center">
                <v-icon :color="headingTextColor" class="mr-2 mt-n1" v-text="icon" :small="dense"></v-icon>
                <div>
                    <h5
                        class="font-weight-regular white--text text-truncate"
                        :class="[`${headingTextColor}--text`,{ 'body-2': dense}]"
                        style="max-width: 250px"
                        v-text="headingTitle"
                    ></h5>
                    <h6
                        v-if="headingSubtitle.length"
                        class="caption text--darken-2 font-weight-light mt-n1"
                        :class="`${headingTextColor}--text`"
                    >
                        {{headingSubtitle}}
                        <v-chip
                            v-if="itemNew"
                            x-small
                            class="mt-n1 ml-1"
                            color="success primary--text text--darken-2 font-weight-regular"
                        >new</v-chip>
                    </h6>
                </div>
            </div>
            <v-spacer></v-spacer>
            <!-- TODO: Replace this with lodash get -->
            <!-- <action-drop-down
                v-if="Object.keys(actions).length"
                :icon="Object.keys(actions).includes('icon') ? action.icon : ''"
                :heading="Object.keys(actions).includes('heading') ? action.heading : ''"
                :items="Object.keys(actions).includes('items') ? action.items : ''"
            ></action-drop-down>-->
        </v-card-title>
        <v-card-text class="mx-4">
            <template v-for="(item,index) in items">
                <v-row :key="`heading-${index}`" v-if="$_.has(item,'heading')">
                    <v-col cols="12">
                        <h4 class="font-weight-regular" v-text="item.heading"></h4>
                    </v-col>
                </v-row>
                <v-row :key="`details-${index}`" v-else>
                    <v-col cols="1" class="mb-n2">
                        <v-icon
                            v-text="item.icon"
                            class="grey--text text--lighten-1"
                            :small="dense"
                        ></v-icon>
                    </v-col>
                    <v-col cols="4" class="mb-n2">
                        <h4
                            class="font-weight-light"
                            :class="{ 'caption': dense }"
                            v-text="item.label"
                        ></h4>
                    </v-col>
                    <v-col cols="7" :class="{'text-right' : item.type === 'chip'}" class="mb-n2">
                        <v-chip
                            v-if="item.type === 'chip'"
                            class="mr-3"
                            small
                            :color="item.color"
                            v-text="item.text"
                        ></v-chip>
                        <div v-else class="d-flex">
                            <h4
                                class="font-weight-bold text-truncate"
                                :class="{ 'caption': dense}"
                                v-text="item.text"
                            ></h4>
                            <psi-copy-button
                                :value="item.text"
                                v-if="item.copy"
                                :dense="dense"
                                btn-class="my-n2 ml-2"
                            ></psi-copy-button>
                        </div>
                    </v-col>
                </v-row>
                <v-row :key="`divider-${index}`">
                    <v-col cols="12" class="my-0 py-0">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
        <template v-slot:progress>
            <v-progress-linear indeterminate color="primary lighten-3" height="5"></v-progress-linear>
        </template>
    </v-card>
</template>
<script>
export default {
    name: "detail-card",
    components: {
        // ActionDropDown: () => import("../menus/ActionDropDown"),
    },
    props: {
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-apps",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        headingTitle: {
            type: String,
            required: true,
        },
        headingSubtitle: {
            type: String,
            required: false,
            default: "",
        },
        headingTextColor: {
            type: String,
            required: false,
            default: "white",
        },
        actions: {
            type: Object,
            required: false,
            default: () => {},
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
        itemNew: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>