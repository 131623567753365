import itemMap from "./item-map.json";

const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

const createItems = (obj) => {
    let items = [];
    Object.keys(obj).forEach((key) => {
        if (Object.keys(itemMap).includes(key)) {
            let item = deepClone(itemMap[key]);
            item.text = obj[key];
            if (item.text.length) {
                items.push(item);
            }
        }
    });
    return items.sort((a, b) => {
        return a.order - b.order;
    });
};
export const transform = (data) => {
    data.forEach((val) => {
        val.Addresses = val.Addresses.map((addr) => {
            return {
                ...addr,
                items: createItems(addr),
            };
        });
        val.Employers = val.Employers.map((employer) => {
            return {
                ...employer,
                items: createItems(employer),
            };
        });
    });
    return data;
};
