<template>
    <div v-cloak>
        <h4 class="font-weight-regular" :class="labelClass">
            {{ label }}
            <v-icon size="10px" color="error" class="mb-1">{{ icon }}</v-icon>
        </h4>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "psi-form-element",
    props: {
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            icon: this.required ? "mdi-circle" : "",
        };
    },
};
</script>
