<template>
    <v-card flat class="mb-2">
        <v-card-text :class="`alternate${colorOption.toString()}`" class="pa-5">
            <div class="d-flex align-center">
                <div class="align-self-center">
                    <h1 class="white--text display-1" v-if="!!icon">
                        <i :class="icon"></i>
                    </h1>
                </div>
                <div class="mx-4">
                    <h3 class="card-title white--text title font-weight-regular" v-text="title"></h3>
                    <h6
                        v-if="!!subTitle"
                        class="card-subtitle white--text op-5 subtitle-2 font-weight-regular"
                        v-text="subTitle"
                    ></h6>
                </div>
            </div>
            <v-row class="mt-1">
                <v-col cols="12" lg="6" class="d-flex align-center">
                    <h4 class="font-weight-light white--text text-nowrap text-h4" v-text="stat"></h4>
                </v-col>
                <!-- <v-col cols="7">
                    <div class="float-right" v-show="elementVisible">
                        <vue-apex-charts
                            type="line"
                            height="70px"
                            width="150px"
                            :options="BandwidthChart.BandwidthChart.chartOptions"
                            :series="BandwidthChart.BandwidthChart.series"
                        ></vue-apex-charts>
                    </div>
                </v-col>-->
            </v-row>
        </v-card-text>

        <v-card-actions
            :class="`alternate${colorOption.toString()} lighten-2`"
            class="d-flex justify-end lighten pa-3"
        >
            <v-btn
                :color="`alternate${colorOption.toString()}`"
                class="white--text text--darken-2"
                :to="to"
            >
                <i :class="icon" class="mr-1"></i>
                View {{title}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
// import VueApexCharts from "vue-apexcharts";
// import BandwidthChart from "./BandWidthData";

export default {
    name: "stats-card",
    props: {
        to: {
            type: [Object, String],
            required: false,
            default: "",
        },
        colorOption: {
            type: Number,
            default: 1,
            required: false,
        },
        stat: {
            type: [String, Number],
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            default: "blue-grey",
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        subTitle: {
            type: String,
            required: false,
            default: "",
        },
    },
    data: () => ({
        elementVisible: false,
        //      BandwidthChart: BandwidthChart,
    }),
    mounted() {},
    created() {},
    components: {
        //     VueApexCharts,
    },
};
</script>
<style scoped>
</style>