<template>
    <v-menu origin="right top" transition="scale-transition" bottom left nudge-top="-40">
        <template v-slot:activator="{ on }">
            <v-btn v-if="icon" icon large v-on="on" class="mx-2">
                <v-icon v-text="icon" :class="iconClass" :large="large"></v-icon>
            </v-btn>
            <v-btn small v-else class="primary" v-on="on" :class="btnClass">
                {{ btnLabel }}
                <v-icon small class="ml-2">mdi-chevron-down</v-icon>
            </v-btn>
        </template>

        <div :style="`width:${width}px;`">
            <div class="d-flex justify-space-between secondary px-4 py-2">
                <div>
                    <h4 class="white--text font-weight-bold" v-text="heading"></h4>
                    <h5 v-if="subheading" class="white--text font-weight-light" v-text="subheading"></h5>
                </div>
                <v-chip small :class="indicator.class" v-if="indicator">{{indicator.label}}</v-chip>
            </div>
            <v-list dense>
                <template v-for="(item,index) in items">
                    <v-divider v-if="'divider' in item" :key="`list-divider-${index}`"></v-divider>
                    <v-list-item
                        v-else
                        class="list-item"
                        link
                        :to="$_.get(item,'to','')"
                        :key="`list-item-${index}`"
                        @click="$emit('action',item)"
                    >
                        <v-list-item-title class="list-item-text">
                            <v-icon
                                small
                                v-text="item.icon"
                                :class="$_.get(item,'class','')"
                                class="mr-3"
                            ></v-icon>
                            <!-- <i class="mr-3" :class="item.icon"></i> -->
                            <span
                                class="text-body-2"
                                :class="$_.get(item,'class','')"
                            >{{ item.title }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </div>
    </v-menu>
</template>
<script>
export default {
    name: "action-drop-down",
    components: {},
    props: {
        btnClass: {
            type: String,
            required: false,
            default: "",
        },
        width: {
            type: String,
            required: false,
            default: "250",
        },
        btnLabel: {
            type: String,
            required: false,
            default: "Actions",
        },
        iconClass: {
            type: String,
            required: false,
            default: "grey--text text--lighten-2",
        },
        large: {
            required: false,
            default: false,
        },
        indicator: {
            type: Object,
            required: false,
            default: null,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        heading: {
            type: String,
            required: true,
        },
        subheading: {
            type: String,
            required: false,
            default: null,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
.list-item:hover {
    background: var(--v-secondary-lighten2);
}
.list-item:hover .list-item-text {
    color: white;
}
</style>
    