var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-element",
    { attrs: { label: _vm.label, required: _vm.required } },
    [
      _c(
        "v-currency-field",
        _vm._b(
          {
            ref: "currency",
            class: [
              { "mt-2": _vm.$vuetify.breakpoint.smAndUp },
              { "mt-1": _vm.$vuetify.breakpoint.smAndDown }
            ],
            attrs: {
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              rules: _vm.rules,
              height: "40",
              solo: "",
              color: "primary",
              dusk: "currency-input",
              label: _vm.label
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", _vm.currency)
              }
            },
            model: {
              value: _vm.currency,
              callback: function($$v) {
                _vm.currency = $$v
              },
              expression: "currency"
            }
          },
          "v-currency-field",
          _vm.currency_config,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }