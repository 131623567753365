// import the api endpoints
import api from "../api";

const RECENT_MESSAGES_LIMIT = 12;

const initialState = () => ({
    messages: [],
    recentMessages: [],
    stats: {},
    conversations: [],
    selectedConversation: [],
    loading: false,
});

const state = initialState();

const getters = {
    messages: (state) => state.messages,
    recentMessages: (state) => state.recentMessages,
    stats: (state) => state.stats,
    conversations: (state) => state.conversations,
    selectedConversation: (state) => state.selectedConversation,
    loading: (state) => state.loading,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async getMessages({ commit }, filter) {
        try {
            commit("SET_LOADING", true);
            const messages = await api.getMessages(0, filter);
            commit("SET_MESSAGES", messages);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async sendMessage({ commit }, messageData) {
        let result = {};
        try {
            commit("SET_LOADING", true);
            result = await api.sendMessage(messageData);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
        return result;
    },
    async createContact({ commit }, contact) {
        let result = {};
        try {
            commit("SET_LOADING", true);
            result = await api.createContact(contact);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
        return result;
    },
    async getRecentMessages({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            commit("SET_LOADING", true);
            const messages = await api.getMessages(RECENT_MESSAGES_LIMIT);
            commit("SET_RECENT_MESSAGES", messages);
            commit("SET_LOADING", false, { root: true });
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },

    async getStats({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            commit("SET_LOADING", true);
            const stats = await api.getStats();
            commit("SET_STATS", stats);
            commit("SET_LOADING", false, { root: true });
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getUserConversations({ commit }, user_id) {
        try {
            commit("SET_LOADING", true, { root: true });
            commit("SET_LOADING", true);
            const conversations = await api.getUserConversations(user_id);
            commit("SET_CONVERSATIONS", conversations);
            commit("SET_LOADING", false, { root: true });
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_MESSAGES: (state, payload) => {
        state.messages = payload;
    },
    SET_RECENT_MESSAGES: (state, payload) => {
        state.recentMessages = payload;
    },
    SET_STATS: (state, payload) => {
        state.stats = payload;
    },
    SET_CONVERSATIONS: (state, payload) => {
        state.conversations = payload;
    },

    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
