import axios from "axios";
// We need the router to handle redirecting on authentication timeout
import store from "@/store";

const httpClient = axios.create({
    withCredentials: true, // Required for laravel sanctum authorization
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

// We will redirect to login if we get an authorization failure
httpClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    // token expired, login
                    store.dispatch("reset");
                    location.replace(process.env.VUE_APP_LOGIN_URL);
                    break;
                default:
                    store.dispatch("Snackbar/showMessage", {
                        content:
                            error.message + " " + error.response.data.message,
                        color: "error",
                    });
            }
        }
        return Promise.reject(error);
    }
);
export default httpClient;
