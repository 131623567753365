var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "h4",
        { staticClass: "font-weight-regular", class: _vm.labelClass },
        [
          _vm._v(" " + _vm._s(_vm.label) + " "),
          _c(
            "v-icon",
            { staticClass: "mb-1", attrs: { size: "10px", color: "error" } },
            [_vm._v(_vm._s(_vm.icon))]
          )
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }