import faker from "faker";
import dayjs from "dayjs";

export default () => {
    const model = {
        id: faker.random.number({ min: 25000, max: 30000 }),
        created_at: dayjs().format("MM/DD/YYYY hh:mm A"),
    };
    return model;
};
