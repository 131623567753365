var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: _vm.btnClass,
      attrs: {
        icon: "",
        title: "Copy " + _vm.label + " to clipboard",
        small: _vm.dense
      },
      on: {
        click: function($event) {
          return _vm.copyItem(_vm.value)
        }
      }
    },
    [
      _c("v-icon", { staticClass: "py-n3", attrs: { small: _vm.dense } }, [
        _vm._v("mdi-content-copy")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }