<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row no-gutters>
            <v-col>
                <v-form ref="form" class="mt-2">
                    <v-file-input
                        solo
                        :id="file.filename"
                        :name="file.filename"
                        @click:clear="clearFile"
                        @change="fileUpload(file.filename)"
                        :placeholder="`Upload your ${file.label}`"
                        :hint="file.hint"
                        :clearable="!loading && clearable"
                        :disabled="loading || disabled"
                        :error="uploadError"
                        persistent-hint
                        :prepend-icon="$vuetify.breakpoint.mdAndUp ? 'mdi-paperclip' : 'mdi-camera'"
                        accept="image/png, image/jpg, image/jpeg, application/pdf, application/docx, application/doc"
                        chips
                        :counter="$vuetify.breakpoint.mdAndUp"
                        :rules="[
                                 v => !!v || `${file.label} is required`,
                                 v => ( v && v.size < $config('files.maxUploadSize')) || `File must be smaller than ${$config('files.maxUploadSizeLabel')}`
                                 ]"
                        :show-size="$vuetify.breakpoint.mdAndUp"
                        :loading="loading"
                        :value="$_.get(file,'file',null)"
                        :ref="file.filename"
                    >
                        <template v-slot:selection="{ text }">
                            <v-chip small label color="primary">
                                <v-icon small class="mr-1" v-if="uploadSuccess">mdi-check-bold</v-icon>
                                {{ text }}
                            </v-chip>
                        </template>
                        <template v-slot:progress>
                            <v-progress-linear color="primary" :value="uploadProgress"></v-progress-linear>
                        </template>
                    </v-file-input>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "psi-form-file-upload",
    components: {
        //  FileUpload
    },
    props: {
        clearable: {
            type: Boolean,
            required: false,
            default: true,
        },
        file: {
            type: Object,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
        csrf_token: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
        applicant_id: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            uploadProgress: 0,
            uploadSuccess: false,
            uploadError: false,
        };
    },
    computed: {},
    watch: {
        file: {
            deep: true,
            immediate: true,
            handler(value) {
                // get is attached to Vue as a lodash replacement
                if (this.$_.get(value, "file", null)) {
                    this.uploadSuccess = value.file.status === "success";
                    // TODO: add commented code block
                    // this.$emit("update", value.file, this.file.id);
                }
            },
        },
    },
    methods: {
        initialize() {
            this.$refs.form.reset();
            this.uploadProgress = 0;
            this.uploadSuccess = false;
            this.loading = false;
            this.uploadError = false;
            this.$emit("update", null, this.file.id);
        },
        clearFile() {
            this.initialize();
        },

        fileUpload(fileName) {
            this.uploadError = false;
            let file = this.$refs[fileName].$refs.input.files[0];

            if (this.$refs.form.validate()) {
                this.uploadProgress = 0;
                this.loading = true;
                // simulate an upload

                // call a file api

                this.loading = false;
                this.$emit("update", file, this.applicant_id);
            }
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>


        