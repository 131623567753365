var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-n2" },
    [
      _c(
        "v-toolbar",
        { staticClass: "pa-0", attrs: { dense: "", flat: "", height: "40px" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "pt-3" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { large: "", color: "primary" } },
                [_vm._v(_vm._s(_vm.icon))]
              ),
              _c("span", { staticClass: "text--primary" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ],
            1
          ),
          _c(
            "v-toolbar-title",
            {
              staticClass: "pb-3",
              attrs: { slot: "extension" },
              slot: "extension"
            },
            [
              _c("v-breadcrumbs", {
                staticClass: "pa-0",
                attrs: { items: _vm.breadcrumbs },
                scopedSlots: _vm._u(
                  [
                    _vm.breadcrumbs
                      ? {
                          key: "divider",
                          fn: function() {
                            return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              }),
              _vm._t("default")
            ],
            2
          ),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }