const initialState = () => ({
    content: "",
    color: "",
});

export const state = initialState();

export const actions = {
    showMessage: ({ commit }, { ...args }) => {
        const content = Object.keys(args).includes("content")
            ? args.content
            : "Error: showMessage missing content parameter";
        const color = Object.keys(args).includes("color")
            ? args.color
            : "secondary";
        commit("SET_MESSAGE", { content, color });
    },
};

export const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_MESSAGE(state, payload) {
        state.content = payload.content;
        state.color = payload.color;
    },
};
