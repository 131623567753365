import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import modules from "./modules";

const initialState = () => ({
    error: "",
    loading: 0,
    initialized: false,
});

export default new Vuex.Store({
    modules,
    state: initialState(),
    mutations: {
        RESET: (state) => {
            const newState = initialState();
            Object.keys(newState).forEach((key) => {
                state[key] = newState[key];
            });
        },
        SET_LOADING: (state, payload) => {
            state.loading = payload ? state.loading + 1 : state.loading - 1;
        },
        SET_ERROR: (state, payload) => {
            state.error = payload;
        },
        SET_INITIALIZED: (state, payload) => {
            state.initialized = payload;
        },
    },
    getters: {
        loading: (state) => (state.loading > 0 ? true : false),
        initialized: (state) => state.initialized,
        error: (state) => state.error,
    },
    actions: {
        reset({ commit }) {
            commit("RESET");
            // resets state of all the modules
            Object.keys(modules).forEach((moduleName) => {
                commit(`${moduleName}/RESET`);
            });
        },
        error({ commit }, message) {
            commit("SET_ERROR", message);
        },
    },
});
