// import the api endpoints
import api from "../api";

const initialState = () => ({
    templates: {},
    allTemplates: [],
    loading: false,
});

const state = initialState();

const getters = {
    templates: (state) => state.templates,
    allTemplates: (state) => state.allTemplates,
    loading: (state) => state.loading,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async deleteTemplate({ commit, dispatch }, template) {
        try {
            await api.deleteTemplate(template);
            dispatch("getAllTemplates");
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async saveTemplate({ commit, dispatch }, template) {
        let result = {};
        try {   
            result = await api.saveTemplate(template);
            dispatch("getAllTemplates");
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
        return result;
    },
    async getTemplates({ commit }) {
        try {
            commit("SET_LOADING", true);
            const templates = await api.getTemplates();
            commit("SET_TEMPLATES", templates);

            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getAllTemplates({ commit }, filter = {}) {
        try {
            commit("SET_LOADING", true);
            const templates = await api.getTemplates(6, true, filter);
            commit("SET_ALL_TEMPLATES", templates);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_TEMPLATES: (state, payload) => {
        state.templates = payload;
    },
    SET_ALL_TEMPLATES: (state, payload) => {
        state.allTemplates = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
