export default {
    navigation: "static",
    items: [
        // {
        //     model: false,
        //     icon: "mdi-file-document",
        //     text: "Blank Page",
        //     to: "/blank",
        // },
        {
            group: "/messages",
            model: false,
            icon: "mdi-forum",
            text: "Messages",
            roles: ["Admin", "PSI Staff"],
            children: [
                {
                    icon: "mdi-view-dashboard",
                    text: "Dashboard",
                    to: "/messages/dashboard",
                    roles: ["Admin", "PSI Staff"],
                },
                {
                    icon: "mdi-message-text",
                    text: "Messages",
                    to: "/messages/view",
                    roles: ["Admin", "PSI Staff"],
                },
                {
                    icon: "mdi-clipboard-account",
                    text: "Orders",
                    to: "/messages/orders",
                    roles: ["Admin", "PSI Staff"],
                },
                {
                    icon: "mdi-account-box-multiple",
                    text: "Conversations",
                    to: "/messages/conversations",
                    roles: ["Admin", "PSI Staff"],
                },
            ],
        },
        {
            group: "/admin",
            model: false,
            icon: "mdi-application-cog",
            text: "Admin",
            roles: ["Admin"],
            children: [
                {
                    icon: "mdi-text-box-multiple",
                    text: "Message Templates",
                    to: "/admin/templates",
                    roles: ["Admin"],
                },
            ],
        },
        {
            group: "/settings",
            model: false,
            icon: "mdi-table-cog",
            text: "Settings",
            roles: ["Admin"],
            children: [
                {
                    icon: "mdi-palette",
                    text: "Application Colors",
                    to: "/settings/colors",
                },
            ],
        },
    ],
};
