<template>
    <v-card class="mt-n2">
        <v-toolbar dense flat class="pa-0" height="40px">
            <v-toolbar-title class="pt-3">
                <v-icon large class="mr-3" color="primary">{{icon}}</v-icon>
                <span class="text--primary">{{ title }}</span>
            </v-toolbar-title>
            <v-toolbar-title class="pb-3" slot="extension">
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template v-slot:divider v-if="breadcrumbs">
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
                <slot></slot>
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    name: "page-title",
    props: {
        title: {
            required: true,
            type: String,
        },
        breadcrumbs: {
            type: Array,
            required: false,
            default: () => [],
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
    },
    data: () => {
        return {};
    },
};
</script>

<style scoped>
</style>