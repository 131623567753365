var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-element",
    { attrs: { label: _vm.label, required: _vm.required } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-menu",
            {
              ref: "menu",
              attrs: {
                "close-on-content-click": false,
                "nudge-right": 40,
                "nudge-bottom": 10,
                lazy: "",
                transition: "scale-transition",
                "offset-y": "",
                "full-width": "",
                "max-width": "290px",
                "min-width": "290px"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          {
                            attrs: {
                              label: _vm.label,
                              placeholder: _vm.label + " in MM/DD/YYYY",
                              mask: "##/##/####",
                              hint: "Enter Date in MM/DD/YYYY",
                              rules: [_vm.verify],
                              "persistent-hint": "",
                              solo: "",
                              height: "40",
                              "return-masked-value": "",
                              "prepend-inner-icon": "event"
                            },
                            on: {
                              input: function($event) {
                                _vm.$emit("input", _vm.formatDate(_vm.date))
                              }
                            },
                            model: {
                              value: _vm.dateFormatted,
                              callback: function($$v) {
                                _vm.dateFormatted = $$v
                              },
                              expression: "dateFormatted"
                            }
                          },
                          on
                        )
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c("v-date-picker", {
                attrs: { "no-title": "", color: "primary" },
                on: {
                  input: function($event) {
                    _vm.menu = false
                  },
                  change: function($event) {
                    _vm.$emit("input", _vm.formatDate(_vm.date))
                  }
                },
                model: {
                  value: _vm.date,
                  callback: function($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }