import MockAdapter from "axios-mock-adapter";

import factory from "@utils/factory";
import user from "./user.model";

// Model factory
factory.define("user", user);

const mocked = (client, routes) => {
    const mock = new MockAdapter(client, { onNoMatch: "passthrough" });

    // Mock the Get USER ROUTE if set
    if (routes.GET_USER.mock) {
        mock.onGet(routes.GET_USER.path).reply(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([200, factory.create("user")[0]]);
                }, routes.GET_USER.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_USER.path).passThrough();
    }
    // Mock the Get USER ROUTE if set
    if (routes.GET_USERS.mock) {
        mock.onGet(routes.GET_USERS.path).reply(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([200, { result: factory.create("user", 10) }]);
                }, routes.GET_USERS.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_USERS.path).passThrough();
    }
};
export default mocked;
