/**
 * Automatically imports all the modules and exports as a single module object
 */

const mocks = {};
if (process.env.VUE_APP_PROTOTYPING) {
    const requireModule = require.context("@", true, /\.mock\.js$/);
    requireModule.keys().forEach((filename) => {
        // create the module name from fileName
        // remove the store.js extension and capitalize
        const moduleName = filename
            .replace(/(\.\/|\.mock\.js)/g, "")
            .split("/")
            .pop()
            .replace(/^\w/, (c) => c.toUpperCase());
        // Only include mock APIs if we are prototyping
        mocks[moduleName] =
            requireModule(filename).default || requireModule(filename);
    });
}
// we'll
export default mocks;
