import httpClient from "@api/client";
import routes from "./messages.routes.json";
import { transform } from "./transform";

const EXCLUDE_FILTER_PARAMS = ["daterange"];

const setup = () => {
    if ("Messages" in window.mocks) {
        window.mocks.Messages(httpClient, routes);
    }
};

const getMessageParams = (limit, filter) => {
    let params = {
        orderBy: "created_at",
        sortedBy: "desc",
    };

    if (limit > 0) {
        params["limit"] = limit;
    }
    if (Object.keys(filter).includes("daterange")) {
        params["daterange"] = filter.daterange;
    }
    if (Object.keys(filter).length > 0) {
        params["searchFields"] = Object.keys(filter)
            .map((key) => {
                return EXCLUDE_FILTER_PARAMS.includes(key) ||
                    filter[key].length == 0
                    ? ""
                    : `${key}:=`;
            })
            .filter(String)
            .join(";");

        params["search"] = Object.keys(filter)
            .map((key) => {
                return EXCLUDE_FILTER_PARAMS.includes(key) ||
                    filter[key].length == 0
                    ? ""
                    : `${key}:${filter[key]}`;
            })
            .filter(String)
            .join(";");
        params["searchJoin"] = "and";
    }
    return params;
};

export default {
    async getUserConversations(user_id) {
        setup();
        let res = await httpClient.get(routes.GET_CONVERSATIONS.path, {
            params: {
                search: user_id,
                searchFields: "user.id",
                with: "user;contact;messages",
                orderBy: "created_at",
                sortedBy: "desc",
            },
        });
        return transform(res.data.data);
    },
    async getMessages(limit = 0, filter = {}) {
        setup();
        let res = await httpClient.get(routes.GET_MESSAGES.path, {
            params: {
                ...getMessageParams(limit, filter),
            },
        });
        return res.data.data;
    },
    async getStats() {
        setup();
        let res = await httpClient.get(routes.GET_STATS.path);
        return res.data.data;
    },
    async sendMessage(messageData) {
        setup();
        let res = await httpClient.post(routes.SEND_MESSAGE.path, messageData);
        return res.data.data;
    },
    async createContact(contact) {
        setup();
        let res = await httpClient.post(routes.CREATE_CONTACT.path, contact);
        return res.data.data;
    },
};
