export const get = (obj, path, def) => {
    var fullPath = path
        .replace(/\[/g, ".")
        .replace(/]/g, "")
        .split(".")
        .filter(Boolean);

    return fullPath.every(everyFunc) ? obj : def;

    function everyFunc(step) {
        return !(step && (obj = obj[step]) === undefined);
    }
};

export const has = function(obj, key) {
    var keyParts = key.split(".");

    return (
        !!obj &&
        (keyParts.length > 1
            ? has(obj[key.split(".")[0]], keyParts.slice(1).join("."))
            : hasOwnProperty.call(obj, key))
    );
};

export const upperFirst = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};
export const camelCase = (str) => {
    return str.replace(/\W+(.)/g, function(_match, chr) {
        return chr.toUpperCase();
    });
};
