var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "mb-3", attrs: { column: "" } },
        [
          _c(
            "h4",
            { staticClass: "font-weight-regular" },
            [
              _vm._v(" Debit or Credit Card "),
              _c(
                "v-icon",
                {
                  staticClass: "mb-1",
                  attrs: { size: "10px", color: "error" }
                },
                [_vm._v("brightness_1")]
              )
            ],
            1
          ),
          _c("v-card", { staticClass: "my-2", attrs: { "elevation-10": "" } }, [
            _c("div", {
              staticClass: "my-3 px-3",
              attrs: { id: "card-element", color: "accent" }
            })
          ]),
          _c("div", {
            staticClass: "mt-2 red--text",
            attrs: { id: "card-errors", role: "alert" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }