<template>
    <v-btn
        icon
        @click="copyItem(value)"
        :class="btnClass"
        :title="`Copy ${label} to clipboard`"
        :small="dense"
    >
        <v-icon class="py-n3" :small="dense">mdi-content-copy</v-icon>
    </v-btn>
</template>
<script>
export default {
    name: "psi-copy-button",
    props: {
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: [String, Number],
            required: true,
        },
        btnClass: {
            type: String,
            required: false,
            default: "ml-2",
        },
        label: {
            type: String,
            required: false,
            default: "text",
        },
    },
    methods: {
        async copyItem(text) {
            await this.$copyText(text);
            this.$toast({ content: `${this.label} copied to clipboard` });
        },
    },
};
</script>

<style scoped>
</style>