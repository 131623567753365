import faker from "faker";
import dayjs from "dayjs";

const getAddresses = (count) => {
    let addresses = [];
    for (let i = 0; i < count; i++) {
        addresses.push({
            address: faker.address.streetAddress(),
            date_in: dayjs()
                .subtract(7, "year")
                .format("MM/DD/YYYY"),
            date_out: dayjs()
                .subtract(5, "year")
                .format("MM/DD/YYYY"),
            reason_for_leaving: faker.lorem.sentence(),
            notes: faker.lorem.sentences(3),
            landlord_name: faker.name.findName(),
            landlord_phone: faker.phone.phoneNumberFormat(),
            landlord_match_name: faker.name.findName(),
            landlord_match_phone: faker.phone.phoneNumberFormat(),
        });
    }
    return addresses;
};
const getEmployers = (count) => {
    let employers = [];
    for (let i = 0; i < count; i++) {
        employers.push({
            address: faker.address.streetAddress(),
            employer_name: faker.company.companyName(),
            start: dayjs()
                .subtract(7, "year")
                .format("MM/DD/YYYY"),
            end: dayjs()
                .subtract(5, "year")
                .format("MM/DD/YYYY"),
            position: faker.name.jobTitle(),
            monthly_income: parseFloat(faker.finance.amount()),
            fullpart: faker.random.arrayElement(["full", "part"]),
            employer_phone: faker.phone.phoneNumberFormat(),
            employer_match_name: faker.name.findName(),
            employer_match_phone: faker.phone.phoneNumberFormat(),
            employer_match_notes: faker.lorem.sentences(2),
        });
    }
    return employers;
};
const getContacts = (model) => {
    let contacts = [];
    contacts.push({
        type: "Property Manager",
        name: faker.name.findName(),
        phone: faker.phone.phoneNumberFormat(),
    });
    contacts.push({
        type: "Applicant",
        name: faker.name.findName(),
        phone: faker.phone.phoneNumberFormat(),
    });
    model.Addresses.forEach((address) => {
        contacts.push({
            type: "Landlord (entered)",
            name: address.landlord_name,
            phone: address.landlord_phone,
        });
        contacts.push({
            type: "Landlord (matched)",
            name: address.landlord_match_name,
            phone: address.landlord_match_phone,
        });
    });
    model.Employers.forEach((employer) => {
        contacts.push({
            type: "Employer (entered)",
            name: employer.employer_name,
            phone: employer.employer_phone,
        });
        contacts.push({
            type: "Employer (matched)",
            name: employer.employer_match_name,
            phone: employer.employer_match_phone,
        });
    });
    return contacts;
};
export default () => {
    const model = {
        SearchId: faker.random.number({ min: 25000, max: 30000 }),
        Created: dayjs().format("MM/DD/YYYY hh:mm A"),
        Status: faker.random.arrayElement(["Pending", "On Hold"]),
        Property: {
            name: faker.company.companyName(),
            company: faker.company.companyName(),
            unit: faker.address.secondaryAddress(),
            rent: parseFloat(faker.finance.amount()),
        },
        Applicant: {
            name: faker.name.findName(),
            type: faker.random.arrayElement(["Applicant", "Cosigner"]),
            order: faker.random.number({ min: 25000, max: 30000 }) + "-1",
        },
        Addresses: getAddresses(3),
        Employers: getEmployers(2),
    };
    model.Contacts = getContacts(model);
    return model;
};
