var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "psi" } },
    [
      _vm.initialized
        ? _c("auth-layout")
        : !_vm.initialized
        ? _c("unauth-layout")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }