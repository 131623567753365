<template>
    <v-container
        class="ml-0 pl-2 pr-2 my-0"
        :class="{ 'py-2': $vuetify.breakpoint.smAndUp, 'py-0': $vuetify.breakpoint.smAndDown}"
        fluid
    >
        <v-row class="hidden-md-and-up mb-2 my-0 py-0 px-0">
            <v-col cols="12" class="py-0 my-0 px-2">
                <v-toolbar dense height="30px" color="primary" dark>
                    <v-toolbar-title v-html="sectionTitle" class="subtitle-2 text-uppercase"></v-toolbar-title>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="auto" class="mx-0 px-0 hidden-sm-and-down py-0 my-0">
                <v-card
                    elevation-0
                    class="ml-4 pr-3 pt-4 hidden-sm-and-down"
                    color="secondary"
                    height="80px"
                    width="200px"
                    dark
                >
                    <p
                        class="font-weight-bold text-right text-uppercase subheading"
                        v-html="sectionTitle"
                    ></p>
                </v-card>
            </v-col>
            <v-col md="auto" class="mx-0 px-0 hidden-sm-and-down py-0 mt-0 mb-2">
                <v-divider vertical class="mx-3 hidden-sm-and-down"></v-divider>
            </v-col>

            <v-col cols="12" :md="wideFirstCol ? 7 : 5" class="px-2 my-0 py-0">
                <slot name="col-1"></slot>
            </v-col>
            <v-col class="px-2 my-0 pt-0 mb-1">
                <slot name="col-2"></slot>
            </v-col>
        </v-row>
        <v-divider class="hidden-sm-and-down ml-1"></v-divider>
    </v-container>
</template>
<script>
export default {
    name: "psi-form-section",
    props: {
        sectionTitle: {
            type: String,
            required: true,
        },
        wideFirstCol: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
