import api from "../api";

const initialState = () => ({
    reference: {},
    loading: false,
    type: null
});

const state = initialState();

const getters = {
    reference: (state) => state.reference,
    loading: (state) => state.loading,
    type: (state) => state.type,
};

const actions = {
    async getReference({ commit }, url) {
        try {
            commit("SET_LOADING", true);
            const reference = await api.getReference(url);
            commit("SET_REFERENCE", reference);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async saveReference({ commit }, form) {
        try {
            commit("SET_LOADING", true);
            const result = await api.saveReference(form);
            commit("SET_REFERENCE", result);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    SET_REFERENCE: (state, payload) => {
        state.type = payload.reference.type;
        state.reference = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
