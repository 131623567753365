import MockAdapter from "axios-mock-adapter";

import factory from "@utils/factory";
import conversation from "./conversation.model";
import message from "./message.model";
import stat from "./stat.model";

import { get } from "@lib/lodash-replace";

// Model factory
factory.define("conversation", conversation);
factory.define("message", message);
factory.define("stat", stat);

const mocked = (client, routes) => {
    const mock = new MockAdapter(client, {
        onNoMatch: "passthrough",
    });

    // Mock the Get USER ROUTE if set
    if (routes.GET_CONVERSATIONS.mock) {
        mock.onGet(routes.GET_CONVERSATIONS.path).reply((config) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        200,
                        factory.create(
                            "conversation",
                            {},
                            get(config, "params.count", 10)
                        ),
                    ]);
                }, routes.GET_CONVERSATIONS.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_CONVERSATIONS.path).passThrough();
    }

    // Mock the Get MESSAGES if set
    if (routes.GET_MESSAGES.mock) {
        mock.onGet(routes.GET_MESSAGES.path).reply((config) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        200,
                        factory.create(
                            "message",
                            {},
                            get(config, "params.count", 10)
                        ),
                    ]);
                }, routes.GET_MESSAGES.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_MESSAGES.path).passThrough();
    }

    // Mock the Get MESSAGES if set
    if (routes.GET_STATS.mock) {
        mock.onGet(routes.GET_STATS.path).reply((config) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        200,
                        factory.create(
                            "stat",
                            {},
                            get(config, "params.count", 10)
                        ),
                    ]);
                }, routes.GET_STATS.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_STATS.path).passThrough();
    }

    // Mock the Get MESSAGES if set
    if (routes.CREATE_CONTACT.mock) {
        mock.onPost(routes.CREATE_CONTACT.path).reply((config) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        200,
                        factory.create(
                            "contact",
                            {},
                            get(config, "params.count", 10)
                        ),
                    ]);
                }, routes.CREATE_CONTACT.delay);
            });
        });
    } else {
        mock.onPost(routes.CREATE_CONTACT.path).passThrough();
    }
};
export default mocked;
