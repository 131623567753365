import { get } from "lodash";
/**
 * Automatically imports all the configurautions
 */
const requireModule = require.context("@", true, /\.config\.js$/);
const modules = {};

requireModule.keys().forEach((filename) => {
    // create the module name from fileName
    // remove the store.js extension and capitalize
    const moduleName = filename
        .replace(/(\.\/|\.config\.js)/g, "")
        .split("/")
        .pop();

    modules[moduleName] =
        requireModule(filename).default || requireModule(filename);
});

export const config = (path) => {
    return get(modules, path);
};
