import httpClient from "@api/client";
import routes from "./user.routes.json";

const setup = () => {
    if ("Users" in window.mocks) {
        window.mocks.Users(httpClient, routes);
    }
};

export default {
    async getUser() {
        setup();
        let res = await httpClient.get(routes.GET_USER.path);
        return res.data;
    },
    async getUsers() {
        setup();
        let res = await httpClient.get(routes.GET_USERS.path);
        return res.data.result;
    },
    async logout() {
        setup();
        await httpClient.post(routes.LOGOUT.path);
    },
};
