import faker from "faker";
import dayjs from "dayjs";

import templates from "./mock-data/templates.mock.json";

export default () => {
    const template = faker.random.arrayElement(templates);
    const model = {
        id: faker.random.number({ min: 25000, max: 30000 }),
        created_at: dayjs().format("MM/DD/YYYY hh:mm A"),
        type: template.type,
        name: template.name,
        body: template.body,
        communication_type: template.communication_type,
        language: template.language,
    };

    return model;
};
