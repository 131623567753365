<template>
    <div>
        <v-layout column class="mb-3">
            <h4 class="font-weight-regular">
                Debit or Credit Card
                <v-icon size="10px" color="error" class="mb-1">brightness_1</v-icon>
            </h4>
            <v-card elevation-10 class="my-2">
                <div id="card-element" class="my-3 px-3" color="accent">
                    <!-- A Stripe Element will be inserted here. -->
                </div>
            </v-card>
            <!-- Used to display Element errors. -->
            <div id="card-errors" class="mt-2 red--text" role="alert"></div>
        </v-layout>
    </div>
</template>
<script>
/* eslint-enable no-undef */
export default {
    name: "psi-form-credit-card",
    components: {},
    props: {},
    computed: {},
    data() {
        return {
            card: null,
            stripe: null,
        };
    },
    mounted() {
        this.initializeStripe();
        this.mountStripe();
    },
    methods: {
        createToken() {
            let _self = this;
            // TODO: Add Name on Card
            this.stripe.createToken(this.card).then((result) => {
                let token = "";
                let error = null;

                if (result.error) {
                    let displayError = document.getElementById("card-errors");
                    displayError.textContent = result.error.message;
                    error = result.error.message;
                } else {
                    token = result.token;
                }
                _self.$emit("update", {
                    error,
                    token,
                });
            });
        },
        initializeStripe() {
            // Create a Stripe client.

            /* eslint-disable no-undef   */
            // "pk_test_N0C2vyYKCHU6MZtalIgOVDqo"
            // Stripe Key is loaded from Laravel onto the window and then added to Vue prototype in app.js
            this.stripe = Stripe(this.stripe_key);
            /* eslint-enable   */
            // Create an instance of Elements.
            let elements = this.stripe.elements({
                fonts: [
                    {
                        cssSrc:
                            "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700",
                    },
                ],
            });

            const style = {
                base: {
                    // Add your base input styles here. For example:
                    fontSize: "16px",
                    color: "#32325d",
                    iconColor: this.$config("settings.colorTheme")["primary"]
                        .primary,
                    fontFamily: "Roboto",
                    padding: "5px",
                },
            };
            // Create an instance of the card Element.
            /* eslint-disable no-undef   */
            this.card = elements.create("card", { style: style });
        },
        mountStripe() {
            let _self = this;

            // Add an instance of the card Element into the `card-element` <div>.
            this.card.mount("#card-element");

            this.card.addEventListener("ready", () => {
                _self.card.focus();
            });

            this.card.addEventListener("change", function (event) {
                var displayError = document.getElementById("card-errors");
                if (event.error) {
                    displayError.textContent = event.error.message;
                    _self.$emit("complete", false);
                } else {
                    displayError.textContent = "";
                    _self.$emit("complete", true);
                }
            });
        },
    },
    created() {
        this.$bus.on("create-token", this.createToken);
    },
    updated() {},
};
</script>

<style scoped>
</style>