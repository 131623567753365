import store from "@/store";
export const routes = [
    {
        path: "/",
        redirect: "/messages/orders",
    },
    {
        name: "Dashboard",
        path: "/messages/dashboard",
        // TODO: Refactor this to the global beforeEnter handler
        beforeEnter: async (to, from, next) => {
            if (!store.getters["User/loggedIn"]) {
                await store.dispatch("User/getUser");
            }
            if (!store.getters["User/verified"]) {
                next({ name: "Verify" });
            } else {
                next();
            }
        },
        component: () => import("@views/messages/MessageDashboard"),
    },
    {
        name: "Messages",
        path: "/messages/view",
        props: (route) => ({
            status: route.query.status,
            order_id: route.query.order_id,
        }),
        beforeEnter: async (to, from, next) => {
            if (!store.getters["User/loggedIn"]) {
                await store.dispatch("User/getUser");
            }
            if (!store.getters["User/verified"]) {
                next({ name: "Verify" });
            } else {
                next();
            }
        },
        component: () => import("@views/messages/MessagesViewer"),
    },
    {
        name: "Orders",
        path: "/messages/orders",
        props: (route) => ({
            inputUser: route.params.user,
        }),
        beforeEnter: async (to, from, next) => {
            if (!store.getters["User/loggedIn"]) {
                await store.dispatch("User/getUser");
            }
            if (!store.getters["User/verified"]) {
                next({ name: "Verify" });
            } else {
                next();
            }
        },

        component: () => import("@views/orders/OrdersProcessing"),
    },
    {
        name: "Conversations",
        path: "/messages/conversations",
        props: (route) => ({
            id: route.query.id,
        }),
        beforeEnter: async (to, from, next) => {
            if (!store.getters["User/loggedIn"]) {
                await store.dispatch("User/getUser");
            }
            if (!store.getters["User/verified"]) {
                next({ name: "Verify" });
            } else {
                next();
            }
        },
        component: () => import("@views/messages/UserConversations"),
    },
    {
        name: "Templates",
        path: "/admin/templates",
        props: (route) => ({
            type: route.query.type,
            communication_type: route.query.communication_type,
            language: route.query.language,
        }),
        beforeEnter: async (to, from, next) => {
            if (!store.getters["User/loggedIn"]) {
                await store.dispatch("User/getUser");
            }
            if (!store.getters["User/verified"]) {
                next({ name: "Verify" });
            } else {
                next();
            }
        },
        component: () => import("@views/templates/MessageTemplates"),
    },
    {
        name: "Colors",
        path: "/settings/colors",
        component: () => import("@views/settings/SettingsColors"),
    },
    {
        name: "Blank",
        path: "/blank",
        component: () => import("@views/pages/BlankPage"),
    },
    {
        name: "Verify",
        path: "/verify",
        beforeEnter: async (to, from, next) => {
            if (!store.getters["User/loggedIn"]) {
                await store.dispatch("User/getUser");
            }
            if (store.getters["User/verified"]) {
                next({ path: "/" });
            } else {
                next();
            }
        },
        component: () => import("@views/auth/VerifyEmail"),
    },
    {
        name: "Reference",
        path: "/reference",
        props: (route) => ({
            url: route.query.url,
            pdf: route.query.pdf === "true",
        }),
        component: () => import("@views/References/RentalReference"),
    },
];
