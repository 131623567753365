export const transform = (data) => {
    let templates = {};
    data.forEach((val) => {
        if (!(val.type in templates)) {
            templates[val.type] = {};
        }
        templates[val.type][val.name] = val;
    });
    return templates;
};
