// import the api endpoints
import api from "../api";

const initialState = () => ({
    orders: [],
    searches: [],
    loading: false,
});

const state = initialState();

const getters = {
    orders: (state) => state.orders,
    searches: (state) => state.searches,
    loading: (state) => state.loading,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async getOrders({ commit }, email) {
        try {
            commit("SET_LOADING", true);
            const orders = await api.getOrders(email);
            commit("SET_ORDERS", orders);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getSearches({ commit }) {
        try {
            commit("SET_LOADING", true);
            const searches = await api.getSearches();
            commit("SET_SEARCHES", searches);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_ORDERS: (state, payload) => {
        state.orders = payload;
    },
    SET_SEARCHES: (state, payload) => {
        state.searches = payload;
    },

    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
