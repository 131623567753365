import faker from "faker";
import dayjs from "dayjs";

export default () => {
    const model = {
        id: faker.random.number({ min: 25000, max: 30000 }),
        created_at: dayjs().format("MM/DD/YYYY hh:mm A"),
        status: {
            id: faker.random.number({ min: 25000, max: 30000 }),
            name: faker.random.arrayElement(["Pending", "On Hold"]),
        },
        applicant: {
            id: faker.random.number({ min: 25000, max: 30000 }),
            fname: faker.name.firstName(),
            lname: faker.name.lastName(),
            full_name: faker.name.findName(),
        },
        user: {
            id: faker.random.number({ min: 25000, max: 30000 }),
            fname: faker.name.firstName(),
            lname: faker.name.lastName(),
            name: faker.name.findName(),
            email: faker.email(),
        },
    };
    return model;
};
