var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        origin: "right top",
        transition: "scale-transition",
        bottom: "",
        left: "",
        "nudge-top": "-40"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.icon
                ? _c(
                    "v-btn",
                    _vm._g(
                      { staticClass: "mx-2", attrs: { icon: "", large: "" } },
                      on
                    ),
                    [
                      _c("v-icon", {
                        class: _vm.iconClass,
                        attrs: { large: _vm.large },
                        domProps: { textContent: _vm._s(_vm.icon) }
                      })
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "primary",
                        class: _vm.btnClass,
                        attrs: { small: "" }
                      },
                      on
                    ),
                    [
                      _vm._v(" " + _vm._s(_vm.btnLabel) + " "),
                      _c(
                        "v-icon",
                        { staticClass: "ml-2", attrs: { small: "" } },
                        [_vm._v("mdi-chevron-down")]
                      )
                    ],
                    1
                  )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        { style: "width:" + _vm.width + "px;" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-space-between secondary px-4 py-2" },
            [
              _c("div", [
                _c("h4", {
                  staticClass: "white--text font-weight-bold",
                  domProps: { textContent: _vm._s(_vm.heading) }
                }),
                _vm.subheading
                  ? _c("h5", {
                      staticClass: "white--text font-weight-light",
                      domProps: { textContent: _vm._s(_vm.subheading) }
                    })
                  : _vm._e()
              ]),
              _vm.indicator
                ? _c(
                    "v-chip",
                    { class: _vm.indicator.class, attrs: { small: "" } },
                    [_vm._v(_vm._s(_vm.indicator.label))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm._l(_vm.items, function(item, index) {
                return [
                  "divider" in item
                    ? _c("v-divider", { key: "list-divider-" + index })
                    : _c(
                        "v-list-item",
                        {
                          key: "list-item-" + index,
                          staticClass: "list-item",
                          attrs: { link: "", to: _vm.$_.get(item, "to", "") },
                          on: {
                            click: function($event) {
                              return _vm.$emit("action", item)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "list-item-text" },
                            [
                              _c("v-icon", {
                                staticClass: "mr-3",
                                class: _vm.$_.get(item, "class", ""),
                                attrs: { small: "" },
                                domProps: { textContent: _vm._s(item.icon) }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "text-body-2",
                                  class: _vm.$_.get(item, "class", "")
                                },
                                [_vm._v(_vm._s(item.title))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }