import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "@plugins/config";
import "@plugins/global";
import "@plugins/lodash";
import "@plugins/toast";
import "@/plugins/clipboard";
import "@/plugins/vue-uuid";
import mocks from "@/plugins/mocks";
import i18n from './i18n'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' 
// We'll store the mocks globally for each api, mocking is enabled via env var
window.mocks = mocks;

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
    locale: 'en',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})

// TODO: Design out dependency on Laravel CSRF token here
Vue.prototype.$app = { csrfToken: "" };

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App)
}).$mount("#app");
