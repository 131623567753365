import Vue from "vue";
import Vuetify from "vuetify/lib";
import settings from "@config/settings.config";

Vue.use(Vuetify);
const theme = settings.colorTheme.primary;

export default new Vuetify({
    theme: {
        options: {
            /* Enabling customProperties will also generate a css variable for each theme color, which you can then use in your components' <style> blocks.
               customProperties allows access to theme colors in <style> blocks of Vue Single file templates via 
               https://vuetifyjs.com/en/customization/theme/

               <style scoped>
                .something {
                    color: var(--v-primary-base);
                    background-color: var(--v-accent-lighten2);
                }
                </style>
            */
            customProperties: true,
        },
        themes: {
            dark: theme,
            light: theme,
        },
        dark: false, // If you want to set dark theme then dark:true else set to false
    },
    rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
