import MockAdapter from "axios-mock-adapter";
import factory from "@utils/factory";
import template from "./template.model";
import { get } from "@lib/lodash-replace";

// Model factory
factory.define("template", template);

const mocked = (client, routes) => {
    const mock = new MockAdapter(client, {
        onNoMatch: "passthrough",
    });

    // Mock the Get USER ROUTE if set
    if (routes.GET_TEMPLATES.mock) {
        mock.onGet(routes.GET_TEMPLATES.path).reply((config) => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([
                        200,
                        {
                            data: factory.create(
                                "template",
                                {},
                                get(config, "params.count", 10)
                            ),
                        },
                    ]);
                }, routes.GET_TEMPLATES.delay);
            });
        });
    } else {
        mock.onGet(routes.GET_TEMPLATES.path).passThrough();
    }
};
export default mocked;
