import faker from "faker";
import dayjs from "dayjs";

export default () => {
    return {
        id: faker.random.uuid(),
        created_at: dayjs().format("MM/DD/YYYY hh:mm A"),
        name: faker.name.findName(),
        avatar: faker.image.avatar(),
        role: faker.random.arrayElement([
            "PSI Staff",
            "Admin",
            "Company Admin",
            "Leasing Agent",
            "Tenant",
            "Property Manager",
        ]),
        email: faker.internet.email(),
    };
};
