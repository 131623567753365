var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mb-4",
          attrs: { dense: "", color: "primary", dark: "" }
        },
        [
          _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c("v-card-text", [_c("h4", [_vm._v(_vm._s(_vm.prompt))])]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { color: "alternate1 lighten-3" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("close")
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-close")]),
              _vm._v("Cancel ")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "secondary" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("confirm")
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-check")]),
              _vm._v("OK ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }