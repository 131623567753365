import MockAdapter from "axios-mock-adapter";
import items from "./mock-data/sidebar.mock.json";

const mocked = (client) => {
    const mock = new MockAdapter(client, {
        onNoMatch: "passthrough",
    });
    mock.onGet("/navigation").reply(200, items);
};
export default mocked;
