var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        {
          staticClass: "pa-5",
          class: "alternate" + _vm.colorOption.toString()
        },
        [
          _c("div", { staticClass: "d-flex align-center" }, [
            _c("div", { staticClass: "align-self-center" }, [
              !!_vm.icon
                ? _c("h1", { staticClass: "white--text display-1" }, [
                    _c("i", { class: _vm.icon })
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "mx-4" }, [
              _c("h3", {
                staticClass: "card-title white--text title font-weight-regular",
                domProps: { textContent: _vm._s(_vm.title) }
              }),
              !!_vm.subTitle
                ? _c("h6", {
                    staticClass:
                      "card-subtitle white--text op-5 subtitle-2 font-weight-regular",
                    domProps: { textContent: _vm._s(_vm.subTitle) }
                  })
                : _vm._e()
            ])
          ]),
          _c(
            "v-row",
            { staticClass: "mt-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center",
                  attrs: { cols: "12", lg: "6" }
                },
                [
                  _c("h4", {
                    staticClass:
                      "font-weight-light white--text text-nowrap text-h4",
                    domProps: { textContent: _vm._s(_vm.stat) }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "d-flex justify-end lighten pa-3",
          class: "alternate" + _vm.colorOption.toString() + " lighten-2"
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "white--text text--darken-2",
              attrs: {
                color: "alternate" + _vm.colorOption.toString(),
                to: _vm.to
              }
            },
            [
              _c("i", { staticClass: "mr-1", class: _vm.icon }),
              _vm._v(" View " + _vm._s(_vm.title) + " ")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }